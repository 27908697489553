<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('Error fetching user data') }}
      </h4>
      <div class="alert-body">
        {{ $t('No user found with this user id. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          {{ $t('User List') }}
        </b-link>
        {{ $t('for other users.') }}
      </div>
    </b-alert>

    <b-tabs
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
        </template>
        <user-edit-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Social') }}</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75" />
      </b-tab> -->
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
// import { ref, onUnmounted } from '@vue/composition-api'
// import router from '@/router'
// import store from '@/store'
// import userStoreModule from './userStoreModule'
import UserEditTabAccount from './components/UserEditTabAccount.vue'
import UserEditTabInformation from './components/UserEditTabInformation.vue'
import UserEditTabSocial from './components/UserEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  // setup() {
  //   const userData = ref(null)

  //   const USER_APP_STORE_MODULE_NAME = 'app-user'

  //   // Register module
  //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

  //   // UnRegister on leave
  //   onUnmounted(() => {
  //     if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  //   })

  //   store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
  //     .then(response => { userData.value = response.data })
  //     .catch(error => {
  //       if (error.response.status === 404) {
  //         userData.value = undefined
  //       }
  //     })

  //   return {
  //     userData,
  //   }
  // },
  data() {
    return {
      userData: {
        id: 1,
        fullName: 'Galen Slixby',
        company: 'Yotz PVT LTD',
        role: 'editor',
        username: 'gslixby0',
        country: 'El Salvador',
        contact: '(479) 232-9151',
        email: 'gslixby0@abc.net.au',
        currentPlan: 'enterprise',
        status: 'inactive',
        avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-4/img/1.9cba4a79.png',
      },
    }
  },
}
</script>

<style>

</style>
